/////////////////////////////////////////////
//** Variables **//
//** This is the file for basic overrides of bootstraps variables. **//
//** You can accomplish a lot of the site setup here. If you need access to more, checkout bootstrap_components/bootstrap/ and find the variables file. **//
/////////////////////////////////////////////


//
// Color system
//

// Setup Colors
$white:  #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:  #000;

$grays: (
        100: $gray-100,
        200: $gray-200,
        300: $gray-300,
        400: $gray-400,
        500: $gray-500,
        600: $gray-600,
        700: $gray-700,
        800: $gray-800,
        900: $gray-900
);

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;
$gold:    #ca820c;

$colors: (
        blue: $blue,
        indigo: $indigo,
        purple: $purple,
        pink: $pink,
        red: $red,
        orange: $orange,
        yellow: $yellow,
        green: $green,
        teal: $teal,
        cyan: $cyan,
        white: $white,
        gray: $gray-600,
        gray-dark: $gray-800,
        gold: $gold
);

$theme-colors: (
        primary: $white,
        secondary: $gold,
        success: $green,
        info: $cyan,
        warning: $yellow,
        danger: $red,
        light: $gray-100,
        dark: $gray-800
);


/////////////////////////////////////////////
//** Table of Contents **//
// Grid Settings
// Body
// Links
// Fonts
// Page Builder Settings
/////////////////////////////////////////////
//** Grid settings **//
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
/////////////////////////////////////////////
//** Body **//
//** Settings for the `<body>` element. **//
$body-bg:       #000;
$body-color:    #fff;
/////////////////////////////////////////////
//** Links **//
//** Style anchor elements. **//
$link-color:            map-get($theme-colors, secondary);
$link-decoration:       none;
$link-hover-color:      lighten($link-color, 15%);
$link-hover-decoration: none;
/////////////////////////////////////////////
//** Fonts **//
//** Font, line-height, and color for body text, headings, and more. **//
/////////////////////////////////////////////

//** GENERAL FONT FAMILIES **//
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
//

// Custom Jeremy Ferrier Fonts
$font-family-open-sans: 'Open Sans', sans-serif;
$font-family-base:       $font-family-open-sans;
/////////////////////////////////////////////
//** FONT SIZES **//
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg:   1.25rem;
$font-size-sm:   1.25rem;
$h1-font-size: (50 / 16 + rem);
$h2-font-size: (50 / 16 + rem);
$h3-font-size: (30 / 16 + rem);
$h4-font-size: (22 / 16 + rem);
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;
/////////////////////////////////////////////
//** FONT MARGINS **//
$h1-margin-bottom: (48 / 16 + rem); // 48px
$h2-margin-bottom: (36 / 16 + rem); // 48px
$h3-margin-bottom: (20 / 16 + rem); // 20px
$h4-margin-bottom: (18 / 16 + rem); // 18px
$h5-margin-bottom: 1rem; // 10px
$h6-margin-bottom: 1rem; // 8px
//** FONT WEIGHTS **/
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.2;
/////////////////////////////////////////////
//** HEADINGS **//
//$headings-margin-bottom: ($spacer / 2) ];
$headings-font-family:   $font-family-open-sans;
$headings-font-weight:   $font-weight-bold;
$headings-line-height:   1.1;
$headings-color:         map-get($theme-colors, secondary);
/////////////////////////////////////////////
/////////////////////////////////////////////
//** GENERAL CONTENT **//
$paragraphs-margin-top: 0;
$paragraphs-margin-bottom: 1rem;
$paragraphs-font-family:   $font-family-open-sans;
$paragraphs-font-weight:   normal;
$paragraphs-line-height:   $line-height-base;
$paragraphs-color:         $white;
/////////////////////////////////////////////

//** PAGE BUILDER SETTINGS **//
$page-builder-block-spacing: 30px;

/////////////////////////////////////////////
$border-width: 1px;

$border-radius:          .25rem;
$border-radius-lg:       .3rem;
$border-radius-sm:       .2rem;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded:            false;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       true;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3)
);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1500px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1480px
);
