/**
 * Architecture Projects
 * @see single-architecture_project.twig
 */



/*
 * Single Architecture Post Slides
 * @see single-architecture_project.twig
 */
.architecture-slide--image {
  position: relative;
  display: block;
  background-size: contain !important;

  @include media-breakpoint-up(md) {
    min-height: 1100px;
    max-height: 1100px;
  }

  &::after {
    content: '';
    display: block;
    padding-bottom: 75%;

    @include media-breakpoint-up(md) {
      padding-bottom: 56.25%;
    }

    @include media-breakpoint-up(xl) {
      padding-bottom: 55%;
    }
  }
}


.tool-pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  .pages {
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    padding: 0;

    li {
      border: 1px solid map-get($theme-colors, secondary);
      border-radius: 10px;
      margin: 0 10px;

      a, span {
        padding: .5em 1em;
        display: inline-block;
      }
    }
  }

  .next, .prev {
    text-transform: uppercase;

    i {
      margin: 0 5px;
    }
  }
}

body.post-type-archive-publications {
  article.type-publications {
    padding: 80px 0;
    border-bottom: 1px solid #fff;

    &.first {
      border-top: none !important;
    }
    &.last {
      border-bottom: none !important;
    }
  }
}



body.single-architecture_project {
  .slider-container {
    position: relative;

    .slider-controls {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);

      .slick-arrow {
        font-size: 2rem;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        color: #fff;
        background-color: #ca820c;
        cursor: pointer;
      }
    }
  }
}

