.background-image
{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &.slide--image {
    background-size: contain;

    @include media-breakpoint-up(md) {
      background-size: cover;
    }
  }
}