// Search form
.search-form {
	@extend .form-inline;
}
.search-form label {
	font-weight: normal;
	@extend .form-group;
}
.search-form .search-field {
	@extend .form-control;
}
.search-form .search-submit {
	@extend .btn;
	@extend .btn-secondary;
}

//Gravity form overwrites

.gform_wrapper {

		input[type=text],
		input[type=email],
		textarea {
			
			font-size: 16px !important;
			margin-bottom: 0;
		 }
		 
		 li { 
			 margin-top: 0 !important;

			 &:last-of-type {

				 margin-top: 20px !important;
			 
				}

		 }

	 	input[type=submit] {

			font-size: 18px !important;
			font-weight: 800;
			background-color: map-get($theme-colors, secondary);
			border: none;
			padding: 1em 1.7em;
		}

	 .hidden_label {
		// margin: 0 !important;
	 }
}