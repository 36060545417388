@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
@import "../../bower_components/owl.carousel/src/scss/owl.carousel.scss";
@import "../../bower_components/slick-carousel/slick/slick.scss";
// endbower

@font-face {
    font-family: BelweStd;
    font-weight: 100;
    src:
    url('../fonts/BelweStd-Light.otf'),
    url('../fonts/Belwe Std Medium.otf'),
    url('../fonts/Belwe Std Bold.otf')
    
}

@import "common/global";
@import "common/variables";
@import "common/typography";
@import "components/mixins";
@import "components/utility-classes";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/profile";
@import "components/shapes";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/nav";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/page-builder";

