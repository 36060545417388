.image-square {
  position: relative;
  height: 0;
  width: 100%;
  background-color: #eee;
  padding-bottom: 100%;
}

.image-rectangle {
  position: relative;
  height: 0;
  width: 100%;
  background-color: #eee;
  padding-bottom: 125%;
}

.image-small-rectangle {
  position: relative;
  width: 100%;
  display: block;

  &::before {
    content: '';
    padding-bottom: 75%;
    display:block;

    @include media-breakpoint-up(md) {
      padding-bottom: 50%;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 35%;
    }
  }
}
