.profile {

    &--position {
        color: #fff;
        font-size: 18px;
        margin-bottom: 1em;
        margin-top: -0.25em;
    }

    &--link {
        color: #fff;
        font-size: 18px;
        font-weight: 300;
        padding-bottom: 1em;
        display: block;
    }

    &-content {

        &--title {
            font-size: 24px;
        }

        &--body {
            p {
                font-size: 16px;
                font-weight: 200;
                letter-spacing: 0.34px;
                line-height: 1.4;
            }
        }
    }
}