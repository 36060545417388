/**
 * Remove list styles for social items partial
 * @see layouts/social-media.twig
 */
.social--items {
	list-style-type: none;
}

/**
 * Studio Awards Table
 * @see content-studio.twig
 */
.awards--row {
	display: flex;
	flex-flow: row wrap;
	margin: 10px 0;

	@include media-breakpoint-up(md) {
		&:not(:last-of-type) {
			margin-bottom: 10px;
		}
	}

	.awards--item {
		flex-grow: 1;
		width: 100%;
		text-align: center;
		margin: 10px 0;

		span {
			font-size: (20 / 16 + rem);
			line-height: 1.5rem;
		}

		&-1 span {
			font-weight: bold;
		}

		@include media-breakpoint-up(md) {
			text-align: left;

			&-1 {
				width:10%;
			}

			&-2 {
				width: 70%;
				padding: 0 15px;
			}

			&-3 {
				width: 20%;
			}
		}

		@include media-breakpoint-up(lg) {
			&-1 {
				width:5%;
			}

			&-2 {
				width: 70%;
				padding-right: 10px;
			}

			&-3 {
				width: 25%;
			}
		}
	}
}

.color-secondary {
	color: map-get($theme-colors, secondary);
}

/**
 * Project Listing CSS,
 * @see page-projects.twig
 */
.project--wrapper {
	position: relative;

	&:hover,
	&:active,
	&:focus {
		.project--title {
			color: map-get($theme-colors, secondary);
		}
	}
}

.project--image {
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		background: rgba(0,0,0,0.4);
		transition: background 0.25s linear;
	}
}

.project--title {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: map-get($theme-colors, primary);
	transition: color .25s linear;

	font-size: $h3-font-size;

	@include media-breakpoint-up(lg) {
		font-size: $h2-font-size;
	}
}

.project-wrapper--toggle {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	background-color: transparent;
	border: none;
}

/*
 * Project Details Hover Styles
 * @see page-projects.twig
 */

body.single-architecture_project {
	.slider-container {
		.slider-dots {
			display: flex;
			justify-content: center;
			ul.slick-dots {
				display: inline-flex;
				list-style: none;
				li {
					margin: .25rem;
					&.slick-active {
						button {
							opacity: 1;
						}
					}

					button {
						cursor: pointer;
						font-size: 0;
						min-width: 14px;
						min-height: 14px;
						border-radius: 7px;
						padding: 0;
						background-color: $white;
						opacity: .6;
					}
				}
			}
		}
	}
}


.project--wrapper {
	position: relative;
}

.project-details {

	&--wrapper {
		position: absolute;
		background: #000;
		width: 100%;
		z-index: 999999;
		top: 50%;
		border: 1px solid map-get($theme-colors, secondary);
		display: none;

		@include media-breakpoint-up(md) {
			position: fixed;
			min-width: initial;
			width: 1100px;
			max-width: 80vw;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			&.flip {
				position: fixed;
				min-width: initial;
				width: 1100px;
				max-width: 80vw;
				top: 50%;
				left: 50%;
			}
		}

		&.active {
			display: block;
		}
	}

	&-close--wrapper {
		display: block;
		text-align: center;

		@include media-breakpoint-up(md) {
			text-align: left;
		}
	}

	&--close {
		color: #fff;
		font-size: (23 / 16 + rem);
		line-height: 1;
	}

	&--title {
		text-align: center;
		border-bottom: 1px solid rgba(0,0,0,0.3);
		margin-bottom: 0;

		@include media-breakpoint-up(md) {
			text-align: left;
			border-bottom: none;
		}
	}

	&-column {
		display: block;
		margin-top: 2rem;

		&--left {
			.project-description {
				p {
					font-size: .9em;
					line-height: 1.5em;
					
				}
			}
		}

		@include media-breakpoint-up(md) {
			display: inline-block;
		}

		.projects--list {
			list-style-type: none;

			@include media-breakpoint-up(md) {
				column-count: 2;
			}
		}

		.project--item {
			text-align: left;
			margin-bottom: 1em;
			text-align: center;

			@include media-breakpoint-up(md) {
				text-align: left;
			}
		}

		.project--link {
			font-size: (18 / 16 + rem);
			line-height: 1.5rem;
			
			color: #fff;

			&:hover {
				text-decoration: underline;
				color: map-get($theme-colors, secondary);
			}
		}

		@include media-breakpoint-up(xl) {
			&.project-details-column--left {
				width: 35%;
				float: left;
			}

			&.project-details-column--right {
				width: 65%;
				float: right;
			}
		}

	}

	&--title {
		font-size: (18 / 16 + rem);
		line-height: 1.5rem;
		margin-bottom: .75rem;
		color: #fff;
		padding: 0;
		margin: 0;
	}
}

.architecture--gallery {
	margin-top: 65px;
}

.architecture-details {
	padding-left: 0;
	border-bottom: 2px solid rgba(255,255,255,1);
	list-style-type: none;

	& .architecture-item {
		& .architecture-detail {
			margin-bottom: 1.25rem;
		}
	}

	.architecture-detail--title {
		font-weight: bold;
	}
}
.archiecture-back--link {
	font-size: 22px;
	text-decoration: underline;
}




// Contact Page

.contact-details {
	padding-left: 0;
}

.contact-detail {
	list-style-type: none;

	& a {
		font-size: 16px;
		color :#fff;

		&:hover {
			cursor: pointer;
			color: map-get($theme-colors, secondary);
		}
	}
}

#gform_1 {
	.gform_body {
		margin-top: -1rem;
	}
}

.details-container {

	& > p { 
	
		font-size: 16px;
		line-height: 1.2;
	}
}

.logo-badge {
	max-width: 170px;
}


//Photography

figure.msnry_item {

	& > a {
		position: relative;
		transition: ease-out 150ms;
		&:hover {

			&:after {
					
				content: "\f002";
				font-family: FontAwesome;
				display: inline-block;
				position: absolute;
				top: 38%;
				left: 40%;
				font-size: 105px;
				font-weight: 300;
				color: #fff;
				z-index: 90210;
				transition: 250ms ease;
			}

			&:before {
				content: "";
				background-color: rgba(0,0,0,0.3);
				width: 100%;
				height: 100%;
				position: absolute;
				transition: 250ms ease;
			}
		}	
	}
}

/**
 * Front Page Slider Styles
 * @see page-front-page.twig
 */
.front-page-slider--wrapper {
	position: relative;
}

.front-page--slider {

	q {
		quotes: "\201C""\201D""\2018""\2019";
		// "\201D""\2018""\2019"
		line-height: 1.5;
		text-shadow: 2px 2px 5px rgba($black, 0.5);

		// @include media-breakpoint-up(md) {
		// 	font-size: ( / 16) + rem;
		// }
	  }

	.slide.overlay {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			background: rgba(0,0,0,0.2);
			z-index: 5;
		}

		.slide-text--wrapper {
			z-index: 20;
		}

	}
	.slide--image {
		position: relative;
		background-position: center;
		background-size: contain;

		&::after {
			content: '';
			padding-bottom: 115%;
			width: 100%;
			display: block;

			@include media-breakpoint-up(sm) {
				padding-bottom: 75%;
			}

			@include media-breakpoint-up(lg) {
				padding-bottom: 60%;
			}
		}
	}

	.slide {
		position: relative;
	}

	.slide-text--wrapper {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 20;

		.slide-text {
			font-size: (18 / 16) + rem;
			font-family: BelweStd;
			line-height: 1.5;
			margin: 0;
			font-weight: 700;
			max-width: 90%;
			width: 950px;
			margin: 0 auto;
			text-align: center;

			@include media-breakpoint-up(md) {
				font-size: (22 / 16 + rem);
			}

			@include media-breakpoint-up(lg) {
				font-size: (36 / 16) + rem;
			}
		}
	}
}

.front-page-slider-nav--wrapper {
	display: flex;
	justify-content: center;
	margin: 20px 0;

	@include media-breakpoint-up(md) {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		justify-content: space-between;
		width: 100%;
		z-index: 99;
	}

	.btn-next, .btn-prev {
		background: rgba(0,0,0,0);
		width: 70px;
		height: 70px;
		max-width: 70px;
		font-size: (40 / 16 + rem);
		border: none;
		outline: none;
		color: #fff;
		margin: 0 10px;
		line-height: 0;
		cursor: pointer;
		font-weight: 100;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		-webkit-appearance: none;

		@include media-breakpoint-up(md) {
			margin: 0;
		}

		@include media-breakpoint-up(xl) {
			max-width: 100px;
			width: 100px;
			height: 100px;
			font-size: (100 / 16 + rem);
		}
	}
}

.acf-map {
	width: 100%;
	height: 600px;
	margin: 20px 0;
}

/* fixes potential theme css conflict */
.acf-map img {
	max-width: inherit !important;
}

#gform_fields_1 {
	input, textarea {
		-webkit-appearance: none !important;
		outline: none;
		border: none;
		padding: 7px 4px;
		border-radius: 0;
	}
}

// Project Photography
.block--project-photography {
	display: inline-flex;
	flex-wrap: wrap;
	width: 100%;
	margin: 0 -1rem;
	&__item {
		display: flex;
		margin: 1rem;
		padding: 1rem;
		width: 100%;
		background-color: grey;
		position: relative;

		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		transition: opacity .5s ease-in-out;

		@include media-breakpoint-up(md) {
			width: 50%;
		}

		@include media-breakpoint-up(lg) {
			width: calc(33.33% - 2rem);
		}

		&:after {
			position: relative;
			content: '';
			padding-bottom: 100%;
			display: block;
		}
		&:before {
			content: '';
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba($black, 0.4);
			transition: opacity .5s ease-in-out;
		}

		&:hover {
			&:before {
				background-color: rgba($black, 0.65);
			}
		}
	}
	&__title {
		position: relative;
		display: inline-block;
		margin: auto;
		z-index: 2;
		text-align: center;
	}
}
// Project Photography -- END
