h1 {  margin-bottom: $h1-margin-bottom;  }
h2 {  margin-bottom: $h2-margin-bottom;  }
h3 {  margin-bottom: $h3-margin-bottom;  }
h4 {  margin-bottom: $h4-margin-bottom;  }
h5 {  margin-bottom: $h5-margin-bottom;  }
h6 {  margin-bottom: $h6-margin-bottom;  }

//p, span
//{
//  margin-top: $paragraphs-margin-top;
//  margin-bottom: $paragraphs-margin-bottom;
//  font-weight: $paragraphs-font-weight;
//  line-height: $paragraphs-line-height;
//  color: $paragraphs-color;
//}

