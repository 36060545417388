 .mobile-nav-primary {
    position: fixed;
    top: 0;
    left: -285px;
    bottom: 0;
    height: 100%;
    width: 285px;
    //background: $theme-dark-blue;
    z-index: 99999999;

   .nav-main-item {}
   .nav-main-link {
     display: block;
     padding: 1em;
   }
   .nav-drop {
     list-style-type: none;
   }
   .nav-drop-item {
     margin: 10px 0;
   }
   .nav-drop-link {
     padding: .5em 0;
     display: block;
   }
  }

  #menu-primary-menu {
    flex-direction: column;
  }

  @include keyframes(slideOpen) {
    from {
      @include transform(translateX(0));
    }

    to {
      @include transform(translateX(285px));
    }
  }

  @include keyframes(slideClosed) {
    from {
      @include transform(translateX(285px));
    }

    to {
      @include transform(translateX(0));
    }
  }

  html {
    position: relative;

    & > body {
      @include animation(0, 0.3s, linear, slideClosed);
    }

    &.loading > body {
      @include animation(0, 0, none, none);
    }

    &.nav-open {
      overflow-x: hidden;

      & > body {
        @include animation(0, 0.3s, ease-out, slideOpen);
      }
    }
  }

  .nav-primary-close--wrapper {
    //color: $theme-gold;

    i {
      margin-right: 5px;
    }
  }