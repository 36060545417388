.site-footer {
  margin-top: 50px;
}

.footer-social--wrapper {

  @include media-breakpoint-up(md) {
    align-items: center;

    .social--items {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    .footer-social--title.text-uppercase {
      margin-bottom: 0;
      margin-right: 15px;
    }
  }

  .footer-social--title {
    font-size: (14 / 16 + rem);
    color: #fff;
    font-weight: $font-weight-bold;
    text-align: center;
    margin-bottom: (10 / 16 + rem);
  }


  .social--items {
    padding-left: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    .social--item {
      margin: 0 10px;
    }

    .social--link {
      & > i {}
    }
  }

}

/**
 * Footer
 * @see footer.twig
 */
.footer-copyright--wrapper {

  @include media-breakpoint-up(md) {
    & > :last-child {
      margin-bottom: 0;
    }
  }

  p {
    font-size: (12 / 16 + rem);
    line-height: ( 24 / 12 );
    color: #fff;
  }
}

.footer-credit--wrapper {

}

.room-design--credit {
  font-size: (12 / 16 + rem);
  line-height: ( 24 / 12 );
  color: #fff;

  & > a {
    text-decoration: underline;
    color: map-get($theme-colors, primary);

    &:hover {
      color: map-get($theme-colors, secondary);
    }
  }
}

.footer-top-bar--wrapper {
  padding: 20px 0;
  position: relative;

  &.background-image {
    &::after{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background: rgba(35,31,32, 0.85);
      z-index: 1;
    }
  }

  & > .container {
    position: relative;
    z-index: 10;
  }
}

.footer-top-bar-text--wrapper {
  text-align: center;

  @include media-breakpoint-up(lg) {
    text-align: left;
  }
}

/**
 * Text Bar
 * @see footer.twig - ul.footer-text-bar-items
 */
.footer-text-bar-items {
  padding: 0;
  list-style-type: none;

  @include media-breakpoint-up(md) {
    display: inline-block;
  }

  .text-bar-item {
    color: map-get($theme-colors, secondary);
    font-weight: bold;
    font-size: 1rem;
    line-height: 1;
    padding: 1em 0;
    margin: 0 auto;
    text-align: center;

    @include media-breakpoint-up(xl) {
      font-size: (20 / 16 + rem);
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid map-get($theme-colors, secondary);
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 0;

    .text-bar-item {
      border-bottom: none;
      padding: 0 1.5em;
      position: relative;
      display: inline-block;
      float: left;


      &:first-of-type {
        padding-left: 0 !important;
      }

      &:not(:last-of-type) {
        border-bottom: none;
        &::after {
          content: '|';
          color: map-get($theme-colors, secondary);
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          transform: translateX(50%);
        }
      }
    }
  }
}