html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and ( min-device-pixel-ratio: 1.25), only screen and ( min-resolution: 200dpi), only screen and ( min-resolution: 1.25dppx) {
	html {
		-webkit-font-smoothing: subpixel-antialiased;
	}
}

body {
	background-color: #000;
	width: 100%;
	height: 100%;

	p {
		font-size: $font-size-lg;
	}
}

.main {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

//** Responsive Video Embeds **//
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	max-width: 100%;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

*
{
	box-sizing: border-box;
}

a {
	&:hover {
		text-decoration: none;
	}
}

