.site-header {
  margin: 30px auto;

  // @include media-breakpoint-up(md) {
  //   margin: 60px auto;
  // }

  // @include media-breakpoint-up(xl) {
  //   margin: 115px auto;
  // }
}

.nav-primary {
  & > ul {
    margin-bottom: 0;
  }

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  & > ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    list-style-type: none;
  }

  .nav-main-item {
    position: relative;
    &:last-of-type {
      .nav-main-link {
        padding-right: none!important;
      }
    }

    &:hover {
      & > .nav-drop {
        max-height: 10000px;
        overflow: visible;
        z-index: 999999999;
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .nav-main-link {
    color: map-get($theme-colors, primary);
    padding: 0.5em 18px;
    font-size: 1.15rem;
    font-weight: 600;
    display: inline-block;

    &:hover {
      text-decoration: none;
      color: map-get($theme-colors, secondary);
    }
  }

  .nav-drop {
    position: absolute;
    bottom: 0;
    left: -50%;
    right: -50%;
    width: 250px;
    transform: translate(0, 100%);
    z-index: -999999999;
    background: #000;
    border: 1px solid map-get($theme-colors, secondary);
    list-style-type: none;
    padding: 1em;
    display: block;

    max-height: 0;
    opacity: 0;
    overflow: hidden;
    z-index: -999999999;
    visibility: hidden;
    transition: max-height 1s linear, opacity .5s ease-in;
  }

  .nav-drop-item {}
  .nav-drop-link {
    color: map-get($theme-colors, primary);
    padding: 0.5em 1.5em;
    font-size: $font-size-lg;
    display: block;
    text-align: center;
    font-size: 1rem;

    &:hover {
      text-decoration: none;
      color: map-get($theme-colors, secondary);
    }
  }
}
.nav-mobile-icon--wrapper {
  font-size: 1rem;
}

@include media-breakpoint-up(sm) {
  .brand-logo {
    width: 250px;
    margin: 0 auto;
    max-width: 100%;
  }
}
